{
  "name": "haunt-map-frontend",
  "version": "2024.10.1",
  "description": "Frontend code for the Haunt Map app",
  "private": true,
  "scripts": {
    "clear-build-cache": "rm -rf .cache/ dist/ .parcel-cache/",
    "format": "prettier --write \"src/**/*.{js,jsx}\"",
    "lint": "eslint \"src/**/*.{js,jsx}\" --quiet",
    "dev": "rm -rf .cache/ dist/ && parcel src/index.html",
    "prod": "npm run clear-build-cache && parcel build src/index.html",
    "dev-prod": "rm -rf .cache/ && parcel build src/index.html"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/mindblender/haunt-map-frontend.git"
  },
  "keywords": [],
  "author": "Jeff Sittler <jsittler@mindblender.com> (https://jsittler.com)",
  "license": "ISC",
  "bugs": {
    "url": "https://github.com/mindblender/haunt-map-frontend/issues"
  },
  "homepage": "https://github.com/mindblender/haunt-map-frontend#readme",
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "devDependencies": {
    "@babel/core": "^7.15.0",
    "@babel/preset-react": "^7.14.5",
    "autoprefixer": "^10.3.2",
    "babel-plugin-transform-remove-console": "^6.9.4",
    "eslint": "^7.32.0",
    "eslint-config-prettier": "^8.3.0",
    "eslint-plugin-import": "^2.24.1",
    "eslint-plugin-jsx-a11y": "^6.4.1",
    "eslint-plugin-react": "^7.24.0",
    "parcel": "^2.0.0-rc.0",
    "postcss": "^8.3.6",
    "prettier": "^2.4.1",
    "process": "^0.11.10",
    "querystring-es3": "^0.2.1",
    "serverless-lift": "^1.29.0"
  },
  "dependencies": {
    "@headlessui/react": "^1.7.2",
    "@heroicons/react": "^2.0.11",
    "@react-google-maps/api": "^2.4.1",
    "@swc/helpers": "^0.2.13",
    "aws-amplify": "^4.2.4",
    "configcat-react": "^4.6.0",
    "mapbox-gl": "^2.5.0",
    "react": "^17.0.2",
    "react-dom": "^17.0.2",
    "react-ga4": "^2.1.0",
    "react-icons": "^4.2.0",
    "react-router-dom": "^5.2.0",
    "react-toastify": "^8.0.3",
    "regenerator-runtime": "^0.13.9",
    "social-links": "^1.4.5",
    "tailwindcss": "^2.2.7"
  }
}